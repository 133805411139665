import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

Vue.use(VueRouter)

  const routes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue'),
    props: (route) => ({...route.query, ...route.params})
  },
  {
    path: '/facturation',
    name: 'Facturation',
    component: () => import('../views/Facturation.vue'),
    props: (route) => ({...route.query, ...route.params})
  },
  {
    path: '/commandes',
    name: 'Commandes',
    component: () => import('../views/Commandes.vue'),
    props: (route) => ({...route.query, ...route.params})
  },
  {
    path: '/commandes_aleaf',
    name: 'CommandesAleaf',
    component: () => import('../views/CommandesRemote.vue'),
    props: (route) => ({...route.query, ...route.params})
  },
  {
    path: '/commandesDetails/:orderID',
    name: 'CommandesDetails',
    component: () => import('../views/CommandesDetails.vue'),
    props: (route) => ({...route.query, ...route.params})
  },
  {
    path: '/recapitulatifPeriode',
    name: 'RecapitulatifPeriode',
    component: () => import('../views/RecapitulatifPeriode.vue'),
    props: (route) => ({...route.query, ...route.params})
  },
  /*{
    path: '/',
    name: 'Home',
    //component: () => import('../views/Home.vue'),
    
  },*/
  { path: '/', redirect: '/facturation'}
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

/*router.beforeEach(async (to, from, next) => {
  if(to.xD) {
    console.log('in beforeEach', from, to )
  }
  if(from.query.cmdType && from.query.cmdType != 1) {
    //to.query.cmdType = from.query.cmdType
    console.log('in beforeEach', from, to )
  }
  next()
})*/
router.beforeEach((to,from,next) => {
  if(to.query.cmdType && store.getters.globalCmdType != to.query.cmdType ) {
    console.log('setting globalCmdType')
    store.commit('setCmdType', parseInt(to.query.cmdType))
  }
  next()
})
export default router
