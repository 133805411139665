import Vue from 'vue'
import Vuex from 'vuex'
//import { jwt, connected, adminConnected }from '../data/userData.js'
import products from './modules/products'
import orders from './modules/orders'
import remoteOrders from './modules/remoteOrders'
import sync from './modules/sync'
import axios from 'axios'
Vue.use(Vuex)
export default new Vuex.Store({
  state: {
    //apiURL: 'http://localhost:4000/api', 
    apiURL: 'https://pecheauchocolat.fr:5000/api',
    //apiURL: '/api',
    client: localStorage.client || '',
    enChargement: false,
    nbChargement: 0,
    messagesAxios: [],
    showMessagesAxiosSnack: false,
    port:'8080',
    isConnected: false,
    isAdminConnected: false,
    cmdType: 1,
  },
  mutations: {
    setClient(state, payload) {
      state.client = payload
      localStorage.setItem('client', state.client)
    },
    setEnChargement(state, payload) {
      console.log('setting en chargement', payload)
      state.enChargement = payload
    },
    incrementNbChargement(state) {
      state.nbChargement++
    },
    decrementNbChargement(state) {
      state.nbChargement--
    },
    addMessageAxios(state, payload) {
      console.log('adding message axios', payload)
      state.messagesAxios.push(payload)
      setTimeout(() => {
        state.messagesAxios.shift()
      }, 5000)
    },
    clearMessagesAxios(state) {
      state.messagesAxios = []
    },
    setShowMessagesAxiosSnack(state, payload) {
      state.showMessagesAxiosSnack = payload
    },
    setLoginState(state, payload) {
      state.isConnected = payload.isConnected
      state.isAdminConnected = payload.isAdminConnected
    },
    setCmdType(state, payload) {
      state.cmdType = payload
    }
  },
  actions: {
    async checkConnectionState({commit, state}) {
      try {
        let connectionSateRes = await axios.get(`${state.apiURL}/connection/state`)
        commit('setLoginState', connectionSateRes.data)
      } catch(err) {
        console.log('error getting connection state ->', err)
      }
    }
  },
  getters: {
    apiURL: (state) => state.apiURL,
    client: state => state.client,
    enChargement: state => state.enChargement,
    nbChargement: state => state.nbChargement,
    messagesAxios: state => state.messagesAxios,
    showMessagesAxiosSnack: state => state.messagesAxios.length > 0,
    port: state => state.port,
    isAdminConnected: state => state.isAdminConnected,
    isConnected: state => state.isConnected,
    globalCmdType: state => state.cmdType
  },
  modules: {
    products,
    orders,
    remoteOrders,
    sync
  }
})