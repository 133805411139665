import axios from 'axios'
export default {
  state: {
    remoteOrders: [],
  },
  actions: {

    async fetchRemoteOrders({ commit, rootState }, payload) {
        console.log('fetching remote orders')
      if(!payload.min) {
        let dateObj = new Date()
        dateObj.setDate(dateObj.getDate())
        dateObj.setHours(1)
        dateObj.setMinutes(0)
        payload.min = dateObj.getTime()
      }
      if(!payload.max) {
        let dateObj = new Date()
        dateObj.setDate(dateObj.getDate())
        dateObj.setHours(23)
        dateObj.setMinutes(59)
        payload.max = dateObj.getTime()
      }
      try {
        let OrdersRes = 
        await axios.get(`${rootState.apiURL}/tunnelToAlif/alifOrders?min=${payload.min/1000}&max=${payload.max/1000}`)
        commit('setRemoteOrders', OrdersRes.data.orders)
      } catch(err) {
        console.log(err)
      }
    },
    //async fetchOrderItems({ commit, rootState }, payload) {}
  },
  mutations: {
    setRemoteOrders(state, payload) {
      state.remoteOrders = payload
    },
  },
  getters: {
    remoteOrders: state => state.remoteOrders,
  }
}