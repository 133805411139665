export default {
  state: {
    toast: 'aya'
  },
  actions: {
  },
  mutations: {
    /*setEnChargement(state, payload) {
      state.enChargement = payload
    }*/
  },
  getters: {
    //enChargement: state => state.enChargement,
  }
}