import axios from 'axios';
//import {mapMutations} from 'vuex'
import store from '../store'
import router from '../router';
export default function setup() {
    axios.interceptors.request.use(function(config) {
        //console.log('requête partie !')
        //store.commit('setEnChargement', true)
        store.commit('incrementNbChargement')
        return config;
    }, function(err) {
        console.log('requête pas partie !', err)
        alert('interceptor reject direct')
        //store.commit('decrementNbChargement')
        return Promise.reject(err);
    });
    axios.interceptors.response.use(function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        store.commit('decrementNbChargement')
        //store.commit('setEnChargement', false)
        return response;
      }, function (error) { 
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        store.commit('decrementNbChargement')
        if( error.response.status == 403) { // si le serveur refuse l'accès,
          console.log('is 403')
          if(router.currentRoute.name != 'Login') {
            //console.log('router current route name -> ', router.currentRoute)
            //document.location.replace('/login')
            router.replace({name: 'Login', query: { cmdType: store.getters.globalCmdType }})
          }
        }
        //store.commit('setEnChargement', false)
        return Promise.reject(error);
      });
}