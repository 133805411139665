import axios from 'axios'
export default {
  state: {
    products: JSON.parse(localStorage.currentCommandeProducts || '[]'),
    products_references_desginations: [],
    productDetailsDialog: false,
    erreurAjoutProduitFacture: '',
    savingProductErrorMessage: '',
  },
  actions: { 
    fetchProductsByReference ({ commit, rootState }, payload) {
      //payload = "3786110302155"
      if(!payload?.reference) {
        console.log('fetchProductsByReference called without payload ->', payload)
        return
      }
      axios.get(`${rootState.apiURL}/products/product/reference/${payload.reference}`)
        .then(response => {
          console.log(response.data)
          if(response.data != null) {
            if(payload.doRestore) {
              commit('restoreProduct', response.data)
            }
            else if(payload.replaceIndex) {
              commit('replaceProductAt', {product: response.data, index: payload.replaceIndex-1})
            } else {
              commit('addProductToProducts', response.data)
              setTimeout(() => window.scrollTo(0,document.body.scrollHeight), 0)
            }
          } else {
            console.log('product with ref ->', payload, ' not found')
            //alert(`'${payload}' NOT FOUND !`)
            commit('addMessageAxios', {message: 'product not found', isError: true})
          }
        })
        .catch(error => {
          console.log('error fecthing prod by ref -> ', error.response.data)
          commit('addMessageAxios', {message: error.response.data, isError: true})
        })
    },
    fetchProductsRefsTitles ({ commit, rootState }) {
      axios.get(`${rootState.apiURL}/products/products_refs_titles`)
        .then(response => {
          if(response.data != null) {
            commit('setProductsRefsTitles', response.data)
          } else {
            console.log('no ProductsRefsTitles found')
          }
        })
        .catch(error => console.log('error fecthing ProductsRefsTitles -> ', error.response.data))
    },
  },
  mutations: {
    addProductToProducts(state, payload) {
      payload.commentaire = ''
      payload.tva = 20.0
      payload.quantity = payload.uniteDeVente
      payload.prixUnitaire_tmp = payload.prixA.toFixed(2)
      payload.montantNet = payload.prixA * payload.uniteDeVente
      payload.des2 = ''
      if(payload.designation) {
        payload.des2 += ` ${payload.designation} `
      }
      if(payload.dimensions) {
        payload.des2 += ` (${payload.dimensions}) `
      }
      if(payload.marque || payload.gamme) {
        payload.des2 += ' | '
      }
      if(payload.marque) {
        payload.des2 += ` ${payload.marque} `
      }
      if(payload.gamme) {
        payload.des2 += ` ${payload.gamme} `
      }
      state.products.push(payload)
      //localStorage.setItem('currentCommandeProducts', JSON.stringify(state.products))
    },
    replaceProductAt(state, payload) {
      console.log('replacing at index ->', payload.index)
      payload.product.commentaire = ''
      payload.product.tva = 20.0
      payload.product.quantity = payload.product.uniteDeVente
      payload.product.prixUnitaire_tmp = payload.product.prixA
      payload.product.montantNet = payload.product.prixA * payload.product.uniteDeVente
      //
      payload.product.des2 = ''
      if(payload.product.designation) {
        payload.product.des2 += ` ${payload.product.designation} `
      }
      if(payload.product.dimensions) {
        payload.product.des2 += ` (${payload.product.dimensions}) `
      }
      if(payload.product.marque || payload.product.gamme) {
        payload.product.des2 += ' | '
      }
      if(payload.product.marque) {
        payload.product.des2 += ` ${payload.product.marque} `
      }
      if(payload.product.gamme) {
        payload.product.des2 += ` ${payload.product.gamme} `
      }
      //
      state.products.splice(payload.index, 1, payload.product)
      //state.products[payload.index] = payload.product

    },
    deleteProductFromProducts(state, index) {
      state.products.splice(index, 1)
      //localStorage.setItem('currentCommandeProducts', JSON.stringify(state.products))
    },
    clearProducts(state) {
      state.products = []
    },
    restoreProduct(state, product) {
      console.log('restoring product', product.reference)
      for(let prod of state.products) {
        console.log('prod --------- ', prod)
        if(prod.reference == product.reference) {
          console.log('found')
          for(let field in product) {
            console.log(field, product[field])
            prod[field] = product[field]
          }
          prod.quantity = product.uniteDeVente
          prod.prixUnitaire_tmp = product.prixA
          prod.montantNet = product.prixA * product.uniteDeVente
        }
      }
    },
    setErreurAjoutProduitFacture(state, payload) {
      state.erreurAjoutProduitFacture = payload
    },
    setProductDetailsDialog(state, payload) {
      state.productDetailsDialog = payload
    },
    setProductsRefsTitles(state, payload) {
      state.products_references_desginations = payload
    }
  },
  getters: {
    products: state => state.products,
    productDetailsDialog: state => state.productDetailsDialog,
    erreurAjoutProduitFacture: state => state.erreurAjoutProduitFacture,
    products_references_desginations: state => state.products_references_desginations,
    savingProductErrorMessage: state => state.savingProductErrorMessage
  }
}