<template>
  <v-app>
    
    <v-app-bar class="hide_on_print" app  clipped-left=""  v-if="true">
      <v-btn text rounded :to="{ path: '/facturation', query: { cmdType: globalCmdType } }" v-if="isConnected"  class="mx-3 subtitle-1">Facturation</v-btn>
      <v-btn text rounded :to="{ path: '/commandes', query: { cmdType: globalCmdType }}" v-if="isConnected" class="mx-3 subtitle-1">CMD</v-btn>
      <v-btn text rounded :to="{ path: '/commandes_aleaf', query: { cmdType: globalCmdType }}" v-if="isConnected" class="mx-3 subtitle-1">CMD Aleaf</v-btn>
      <v-spacer></v-spacer>
      <v-btn text rounded dark @click="logout" v-if="isConnected" class="mx-3 subtitle-1 black">SE DECONNECTER</v-btn>
      <v-btn text rounded  @click="toLoginPage" v-if="!isConnected && $router.currentRoute.name != 'Login'" class="mx-3 subtitle-1 success">SE CONNECTER</v-btn>
    </v-app-bar>
    <v-main class="margin-top-3-screen">
      <router-view/>
      <Chargement v-if="nbChargement > 0"/>
        <v-snackbar v-model="showMessagesAxiosSnack" :timeout="messagesSnackTimeout" class="hide_on_print">
          <ol>
            <li v-for="(message,i) of messagesAxios" :key="i">
            <p class="caption text-uppercase" :class="{'red--text': message.isError, 'green--text': !message.isError}">{{message.message}}</p>
          </li>
          </ol>
          
        </v-snackbar>
    </v-main>
  </v-app>
</template>
<script>
import {mapActions, mapGetters, mapMutations} from 'vuex'
import Chargement from './components/Chargement'
import axios from 'axios'

export default {
  data: () => ({
    showMessagesAxiosSnack: false,
    messagesSnackTimeout: -1
  }),
  components: {
    Chargement,
  },
  methods: {
    ...mapActions(['fetchProductsRefsTitles', 'fetchOrders', 'checkConnectionState']),
    ...mapMutations(['setShowMessagesAxiosSnack', 'addMessageAxios']),
    toLoginPage() {
      this.$router.replace({
          name: 'Login'
        })
    },
    async logout() {
      try {
        await axios.post(`${this.apiURL}/connection/logout`, {}, {})
        this.addMessageAxios({message: 'Vous êtes maintenant déconnecté !'})
        this.checkConnectionState()
        this.$router.replace({
          name: 'Login'
        })
      } catch(error) {
        //console.log('error while login -> ', error)
        this.addMessageAxios({message: error.response.data, isError: true})
      }
    }
  },
  computed: {
    ...mapGetters(['enChargement', 'nbChargement', 'messagesAxios', 'apiURL', 'isAdminConnected', 'isConnected', 'globalCmdType']),
    /*showMessagesAxiosSnackLocal: {
      get() {
        return this.showMessagesAxiosSnack
      },
      set(maj){
        this.setShowMessagesAxiosSnack(maj)
      }
    }*/
  },
  watch: {
    messagesAxios(maj) {
      console.log('messages axios changed ->', maj)
      
      if(maj.length > 0) {
        this.showMessagesAxiosSnack = true
      } else {
        this.showMessagesAxiosSnack = false
      }
      if(maj.length == 1) {   
        this.messagesSnackTimeout = 5000
      } else if(maj.length > 1) {
        this.messagesSnackTimeout = -1
      }
    }
  },
  async created() {
    await this.checkConnectionState()
    if(!this.isConnected) {
      this.$router.replace({name: 'Login', query: { cmdType: this.globalCmdType}})
    } else {
      this.fetchProductsRefsTitles()
      this.fetchOrders({})
    }
    //this.fetchOrders({})
    /*window.addEventListener("beforeunload", function (e) {
      var confirmationMessage = 'Si vous quittez la page, certaines informations seront perdues';
      (e || window.event).returnValue = confirmationMessage; //Gecko + IE
      return confirmationMessage; //Gecko + Webkit, Safari, Chrome etc.
    });*/
    //setTimeout(() => window.scrollTo(0,document.body.scrollHeight), 0)
  }
}
</script>
<style scoped>
/*.material-design-icon > .material-design-icon__svg {
  height: 24px;
  width: 24px;
  bottom: -4px;
  right: 2px;
}*/
  .v-main{
    padding-top: 20px !important;
    /*padding-left: 50mm !important;*/
  }
@media screen {
  .margin-top-3-screen {
    margin-top: 50px;
  }
}
@media print {
  .hide_on_print {
    display: none;
  }

/*
  @page { 
      size: auto;   
      margin-bottom: 1mm;  
      margin-top: 1mm;
    } */
    body { 
        padding-top: 72px; 
        padding-bottom: 72px ; 
    } 
}
a { text-decoration: none; }
</style>
