import axios from 'axios'
export default {
  state: {
    test: '',
    orders: [],
    detailsCommandeDialogue: false,
  },
  actions: {
    async deleteOrder ({ rootState, commit, dispatch }, payload) {
      let confirmed = confirm('Voulez vous vraiment supprimer cette commande ?')
      console.log(payload)
      console.log(confirmed)
      if(confirmed) {
        await axios.delete(`${rootState.apiURL}/orders/order/${payload.id}`)
        commit('addMessageAxios', {message: 'successfully deleted order'})
        dispatch('fetchOrders', {})
      }
    },
    async saveOrder ({ rootState, dispatch, commit }, payload) {
      for(let item of payload.items) {
        if(item.montantNet == 0) {
          return commit('addMessageAxios', {message: `item ${item.reference} montant = 0`, isError: true})
        }
      }
      try {
        if(!payload.id) {
          await axios.post(`${rootState.apiURL}/orders/order`, payload, {})
        } else {
          await axios.post(`${rootState.apiURL}/orders/update_order`, payload, {})
        }
        dispatch('fetchOrders', {})
        if(!payload.id) {
          commit('clearProducts')
        }
        commit('addMessageAxios', {message: 'successfully saved order'})
      } catch(err) {
        console.log(err)
        commit('addMessageAxios', {message: err.response.data, isError: true})
      }
      //commit('toast', 'xD')
    },
    async fetchOrders({ commit, rootState }, payload) {
      if(!payload.min) {
        let dateObj = new Date()
        dateObj.setDate(dateObj.getDate())
        dateObj.setHours(1)
        dateObj.setMinutes(0)
        payload.min = dateObj.getTime()
      }
      if(!payload.max) {
        let dateObj = new Date()
        dateObj.setDate(dateObj.getDate())
        dateObj.setHours(23)
        dateObj.setMinutes(59)
        payload.max = dateObj.getTime()
      }
      try {
        let OrdersRes = 
        await axios.get(`${rootState.apiURL}/orders/ordersBetween/${payload.min}/${payload.max}?cmdType=${rootState.cmdType}`)
        commit('setOrders', OrdersRes.data)
      } catch(err) {
        console.log(err)
      }
    },
  },
  mutations: {
    setOrders(state, payload) {
      state.orders = payload
    },
    setDetailsCommandeDialogue(state, payload) {
      state.detailsCommandeDialogue = payload
    }
  },
  getters: {
    orders: state => state.orders,
    detailsCommandeDialogue: state => state.detailsCommandeDialogue
  }
}